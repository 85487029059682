<template>
  <div>
    <bread-crumb v-if="getUser" :selected="getUser.name"></bread-crumb>
    <section class="about-company container container__mini2">
      <div
        class="about-company__descr"
        v-html="data.description_children"
      ></div>

      <h2 class="about-company__title">{{ data.title_children }}</h2>
      <div class="about-company__people people-company" v-if="getUser">
        <div class="people-company__left">
          <div class="people-company__img" v-if="getUser.image">
            <img
              :src="url + getUser.image"
              rel="preload"
              width="583"
              height="700"
              alt="sdfds"
            />
          </div>
        </div>
        <div class="people-company__right">
          <div class="people-company__name">{{ getUser.name }}</div>
          <div class="people-company__position">{{ getUser.role }}</div>
          <div class="people-company__descr">
            <!--            <h3 class="people-company__descr&#45;&#45;title">Lorem ipsum</h3>-->
            <div
              class="people-company__descr--text"
              v-html="getUser.description"
            ></div>
          </div>
          <div class="people-company__social">
            <a class="fb" :href="getUser.facebook">facebook</a>
          </div>
          <div class="people-company__phrase">
            {{ getUser.slogan }}
          </div>
        </div>
      </div>
    </section>

    <SliderAboutCompany :data="users" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const SliderAboutCompany = defineAsyncComponent(() =>
  import("@/components/SliderAboutCompany.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import axios from "axios";

export default {
  components: {
    BreadCrumb,
    SliderAboutCompany,
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  computed: {
    getUser: {
      get() {
        let result = null;
        this.users.forEach((element) => {
          if (element.slug === this.$route.params.name) {
            result = element;
          }
        });
        return result;
      },
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      data: [],
      users: [],
    };
  },
  methods: {
    async getAboutComPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/about-company-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getSliders() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/all-sliders",
          config
        );
        this.users = response.data.about_company_slider.data.users.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getAboutComPage();
    this.getSliders();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.about-company {
  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }
}

.people-company {
  display: grid;
  @include property("margin-bottom", 150, 50);
  @include property("gap", 85, 40, true, 1200, 768);

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767.98px) {
    gap: 20px;
  }

  &__left {
    display: flex;
    justify-content: center;
    align-self: flex-start;

    @media (max-width: 767.98px) {
      margin-right: 20px;
    }

    @media (max-width: 575.98px) {
      margin-right: 15px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    max-width: 430px;
    @include property("margin-top", 70, 30, true, 1670, 1024);

    @media (max-width: 767.98px) {
      margin-top: 0;
    }
  }

  &__img {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 20px;
      width: 100%;
      height: 100%;
      border: 1px solid #f9b80e;

      @media (max-width: 575.98px) {
        top: -15px;
        left: 15px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 700px;
      object-fit: contain;
    }
  }

  &__name {
    font-weight: 700;
    @include property("font-size", 32, 24);
    line-height: 1;
    letter-spacing: 0.64px;
    color: #f9b80e;
    margin-bottom: 1em;
  }

  &__position {
    @include property("font-size", 24, 20);
    line-height: calc(31 / 24);
    letter-spacing: 0.48px;
    color: #1c1c1c;
    margin-bottom: 1.3em;

    @media (max-width: 1023.98px) {
      margin-bottom: 1em;
    }
  }

  &__descr {
    &--title {
      font-weight: 700;
      @include property("font-size", 24, 20);
      letter-spacing: 0.48px;
      color: #f9b80e;
    }

    &--text {
      letter-spacing: 0.32px;
      color: #1c1c1c;
      @include property("margin-bottom", 35, 16);
    }
  }

  &__social {
    @include property("margin-bottom", 100, 25);
    a {
      display: block;
      font-size: 0;
      width: 35px;
      height: 35px;
      background: url("~@/assets/images/fb.svg") no-repeat;
      background-size: 100% 100%;
    }
  }

  &__phrase {
    @include property("font-size", 24, 20);
    line-height: calc(31 / 24);
    font-style: italic;
    letter-spacing: 0.48px;
    color: #f9b80e;
    margin-top: auto;

    @media (min-width: 768px) {
      margin-bottom: 15px;
    }
  }
}
</style>
